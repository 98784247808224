<template lang="pug">
  .user-manage.d-none
    .container
      .row.justify-content-md-center
        .col-lg-10
          .text-center.m-4
            h1 支払情報削除
            form.needs-validation(@submit="exec" ref="form" method="POST" novalidate)
              .form-group
                input.btn.btn-primary.col.col-md-3(type="submit" value="支払情報削除" v-bind:disabled="isDisabled" ref="submit")
                p.text-danger.mt-3 ※支払い情報を削除すると有料プランが利用できなくなります
              input(type="hidden" name="pay_method" ref="paymethod")
              input(type="hidden" name="merchant_id" value="74522")
              input(type="hidden" name="service_id" value="001")
              input(type="hidden" name="cust_code" ref="userId")
              input(type="hidden" name="terminal_type" value="0")
              input(type="hidden" name="success_url" ref="successUrl")
              input(type="hidden" name="cancel_url" ref="cancelUrl")
              input(type="hidden" name="error_url" ref="errorUrl")
              input(type="hidden" name="pagecon_url" ref="pageconUrl")
              input(type="hidden" name="request_date" ref="requestDate")
              input(type="hidden" name="limit_second" value="600")
              input(type="hidden" name="sps_hashcode" ref="hashcode")
</template>

<script>
import { createHash } from 'crypto'
import axios from 'axios'

export default {
  data() {
    return {
      message: '',
      isDisabled: false,
    }
  },
  mounted() {
    this.$refs.form.action = process.env.VUE_APP_PAY_CREDIT_DEL

    this.$refs.successUrl.value = location.origin + '/user/payment/del_complete'
    this.$refs.cancelUrl.value = location.origin + '/user/payment/info'
    this.$refs.errorUrl.value = location.origin + '/'
    this.$refs.pageconUrl.value = process.env.VUE_APP_API_GATEWAY + '/api-pay'

    const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user'
    axios.get(jsonUrl, {
      headers: {
        'Authorization': this.$user.idToken
      }
    })
    .then(res => {
      this.$refs.paymethod.value = res.data.payment
      this.$refs.submit.click()
    })
    .catch(err => {
      console.log(err)
    })
  },
  methods: {
    exec(e) {
      if(this.$refs.paymethod.value == 'credit') {
        this.isDisabled = true

        this.messages = ''

        //日付取得
        let date = new Date () 

        let year = date.getFullYear() 	// 年
        let month = ('0' + (date.getMonth() + 1)).slice(-2)	// 月
        let day = ('0' + date.getDate()).slice(-2) //日
        let hour = ('0' + date.getHours()).slice(-2) 	// 時
        let minute = ('0' + date.getMinutes()).slice(-2)	// 分
        let second = ('0' + date.getSeconds()).slice(-2) 	// 秒

        this.$refs.requestDate.value = '' + year + month + day + hour + minute + second
        this.$refs.userId.value = this.$user.userId

        // ハッシュ値設定
        this.createCheckHash(this.$refs.form)
      }else {
        const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user'
        const params = {}
        axios.patch(jsonUrl, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(res => {
          this.$router.push('/user/payment/del_complete')
        })
        .catch(err => {
        })
        e.preventDefault()
        return false
      }
    },
    createCheckHash(e) {
      const hash = process.env.VUE_APP_PAY_HASH 
      let str = ''
      e.children.forEach(elm => {
        if (elm.type !== 'hidden') return
        str += elm.value
      })
      let shasum = createHash('sha1')
      shasum.update(str + hash)
      this.$refs.hashcode.value = shasum.digest('hex')
    }
  }
}
</script>